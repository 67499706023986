<template>
  <!--内容-->
  <div class="page-user-home">
    <div class="banner">
      <img :src="background_img" alt="作者主页banner-由作者自定义上传">
      <div class="static">
        <div class="item" v-for="(m,index) in authorStatistics" :key="index"><span>{{ m.num }}</span><i>{{ m.name }}</i></div>
      </div>
    </div>
    <div class="user-main">
      <div class="gk-clear" style="width: 1538px;margin: 0 auto;">
        <div class="side-bar">
          <div class="top">
            <!-- 作者信息-->
            <div class="user-info">
              <img :src="userInfo.avatar" alt="">
              <div class="name">{{ userInfo.nickname }}</div>
              <div class="tag">{{ userInfo.residecity }} ｜ {{ userInfo.profession }}</div>
              <div class="desc">
                <span>{{ userInfo.introduction }}</span>
              </div>
              <div class="btns">
                <span class="btn01" @click="attentionUser">{{attention_txt}}</span>
<!--                <span class="btn02">向Ta下需求单</span>-->
              </div>
              <!--  具体信息 -->
              <div class="det-info">
                <p><i>昵称</i><span>{{ userInfo.nickname }}</span></p>
                <p><i>性别</i><span>{{ userInfo.gender }}</span></p>
                <p><i>城市</i><span>{{ userInfo.resideprovince }}/{{ userInfo.residecity }}</span></p>
                <p><i>职业</i><span>{{ userInfo.profession }}</span></p>
                <p><i>工作时间</i><span>{{ userInfo.workday }}</span></p>
              </div>
            </div>
            <!-- 最近访客  -->
            <div class="module-visitor">
              <div class="tit">最近访客</div>
              <ul>
                <li v-for="(m,index) in userVisitor" :key="index">
                  <el-image :src="m.visitor_user_avatar" lazy>
                    <template #error>
                      <el-image src="/static/upload/tx2.png" lazy></el-image>
                    </template>
                  </el-image>
                  <span>{{ m.visitor_nickname }}</span>
                  <i>{{ m.show_time }}</i>
                </li>
              </ul>
            </div>
          </div>
          <!--   喜欢该作品的还看了 列表-->
          <div class="relate-list">
            <div class="tit">喜欢该作者的还看了</div>
            <ul>
              <li v-for="(m,index) in recommend_list" v-bind:key="index">
                <div class="pic">
                  <router-link :to="'/detail/'+m.id" target="_blank">
                    <img :src="m.img" :alt="m.title">
                  </router-link>
                </div>
                <div class="txt">
                  <h3><router-link :to="'/detail/'+m.id" target="_blank">{{m.title}}</router-link></h3>
                  <div class="price"><span>作品定价</span>¥{{m.price}}</div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="right-content">
          <div class="top">
            <div class="title-mod">
              <div class="left">
                <span class="active">Ta的创作</span>
              </div>
              <div class="right">
                <span class="mark">最新上传</span>
<!--                <span>最热下载</span>-->
              </div>
            </div>
            <div class="cate">
              <div class="item" :class="{active:this.cid==0}" @click="typeChange(0)">全部</div>
              <div class="item" v-for="(m,index) in type_data" v-bind:key="index" :class="{active:this.cid==m.id}" @click="typeChange(m.id)">{{ m.name }}</div>
            </div>
          </div>
          <!--  列表  -->
          <div class="user-list scheme-list-mod">
            <ul>
              <li v-for="(m,index) in data_list" v-bind:key="index">
                <div class="pic">
                  <router-link :to="'/detail/'+m.id">
                    <img :src="m.img" :alt="m.title">
                  </router-link>
                </div>
                <h3><router-link :to="'/detail/'+m.id" target="_blank">{{m.title}}</router-link></h3>
                <div class="desc">{{m.description}}</div>
                <div class="info">
                  <div class="author"><img :src="m.user.avatar" alt=""><span>{{ m.user.nickname }}</span></div>
                  <div class="viewer"><img src="/static/iconImg/icon-view.png" alt=""><span>{{m.view}}</span></div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import article from "@/api/article";
import user from "@/api/user";

export default {
  name: "utterer",
  components:{},
  data() {
    return {
      background_img:'/static/images/user-banner.png',
      uid:0,
      cid:0,
      per_page: 0,
      total: 0,
      current_page: 1,
      last_page: 0,
      data_list:[],
      recommend_list:[],
      type_data:[],
      authorStatistics:[],
      userInfo:[],
      userVisitor:[],
      attention_txt:"+ 关注",
    }
  },
  created() {
    this.uid = this.$route.params.uid;
    this.getBackgroundImg();
    this.getAuthorStatistics();
    this.getUserinfo();
    this.getCategoryNameList();
    this.getUserList();
    this.getRecommendList();
    this.visitorAdd();
    this.getUserVisitor();
  },
  methods: {
    //获取 作者 主页图片
    getBackgroundImg(){
      user.getBackgroundImg({
        uid:this.uid
      }).then(res=>{
        if (res.code == 0) {
          this.background_img = res.data.background_img;
        } else {
          this.$message.error({
            message: res.msg
          });
        }
      })
    },
    //关注作者
    attentionUser() {
      user.attentionUser({
        uid: this.uid
      }).then(res => {
        if (res) {
          this.attention_txt=res.data.is_delete==1?"+ 关注":" 已关注";
          //this.$message.success(res.msg)
        }
      })
    },
    //添加访客记录
    visitorAdd(){
      article.visitorAdd({
        uid: this.uid
      }).then(res => {
        if (res) {
          console.log(res);
        } else {
          console.log(res);
        }
      })
    },
    //获取用户访客记录
    getUserVisitor(){
      article.getUserVisitor({
        uid: this.uid,
        pagesize:4
      }).then(res => {
        if (res) {
          this.userVisitor = res.data;
        } else {
          console.log(res);
        }
      })
    },
    //获取用户信息
    getUserinfo(){
      this.loading = true
      article.getUserinfo({
        uid: this.uid
      }).then(res => {
        if (res) {
          this.userInfo = res.data;
          this.attention_txt = res.data.attention_txt;
        } else {
          console.log(res);
        }
      })
    },
    //调取 用户 作品，关注，收藏 统计
    getAuthorStatistics() {
      this.loading = true
      article.getAuthorStatistics({
        uid: this.uid
      }).then(res => {
        if (res) {
          this.authorStatistics = res.data;
        } else {
          console.log(res);
        }
      })
    },
    //切换 类型 选项卡
    typeChange(cid){
      this.cid = cid;
      this.getUserList()
    },
    //首页 分类选项卡
    getCategoryNameList() {
      article.getCategoryNameList({
        ident:'type'
      }).then(res=>{
        if (res) {
          this.type_data = res.data;
        }
      })
    },
    //调取 作者列表数据
    getUserList() {
      article.getUserList({
        uid:this.uid,
        type:this.cid,
        page: this.current_page
      }).then(res=>{
        if (res) {
          this.data_list = res.data.data;
        }else{
          console.log(res);
        }
      })
    },
    //调取 推荐列表数据
    getRecommendList() {
      article.getListRows({
        cid:0,
        pagesize:6
      }).then(res=>{
        if (res) {
          this.recommend_list = res.data;
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
</style>